import React , {Component} from 'react';
import {TopPanel, Button, TopPanelContent, MiddlePanel, MiddlePanelSecond, MiddleFooterSection, FooterSection, IntegrationContentContainer} from './styles';
import PropTypes from "prop-types";
import Slider from "react-slick-16";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const dotNames = ["Tips","Article & Quizzes","Video"];
const dotNames1 = ["Article & Quizzes","Video","Tips"];
class WellnessPage extends Component{
  TopPanelSection = (history) => (
    <TopPanel>
      <div>
        <p>IT’S ALL ABOUT ME</p>
        <h1>Wellness Personalized</h1>
        <TopPanelContent>
          <p>
            Give your employees a completely unique dashboard with daily tips, wellness videos, education, quizzes and more!
          </p>
        </TopPanelContent>
        <Button onClick={()=> history.push('/contact')}>Get Started</Button>
      </div>
      <div>
        <picture>
          <img src="/public/images/WellnessPage/topsectionwellness.png"/>
        </picture>
      </div>
    </TopPanel>
  );
  MiddlePanelSection = () => (
    <MiddlePanel>
      <div>
        <img src="/public/images/WellnessPage/middle1.png"/>
      </div>
      <div>
        <h1>Boost engagement with<br /> an activity challenge <br />amongst colleagues</h1>
        <TopPanelContent>
          <p>
            Launch contests with the click of a button,
            <br /> automatically sending push notifications and
            <br />email reminders to communicate to your
            <br /> entire team. These friendly competitions spark
            <br />conversation and build camaraderie.
          </p>
        </TopPanelContent>
      </div>
    </MiddlePanel>
  );
  MiddlePanelSectionSecond = () => (
    <MiddlePanelSecond>
      <div>
        <h1>Address company<br />health concerns with<br /> aggregated data</h1>
        <TopPanelContent>
          <p>
            Employees complete their own health
            <br /> assessment on their mobile app or web portal.
            <br />Then using AI, we personalize the program to<br /> the individual employee.
          </p>
        </TopPanelContent>
      </div>
      <div>
        <img src="/public/images/WellnessPage/middle2.png"/>
      </div>
    </MiddlePanelSecond>
  );
  middleFooterSection = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      draggable: true,
      arrows: false,
      centerPadding: "25%",
      dotsClass: "slick-dots custom-dots",
      centerMode: true,
      initialSlide: 1,
      customPaging: (index) => (
        <ul>
          <li><button /></li>
          <h4>{dotNames[index]}</h4>
        </ul>
      ),
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            dots: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
            arrows: false,
            dotsClass: "slick-dots custom-dots",
            centerMode: false,
            customPaging: (index) => (
              <ul>
                <li><button /></li>
                <h4>{dotNames1[index]}</h4>
              </ul>
            ),
          }
        },
      ]
    };
    return <MiddleFooterSection>
      <div>
        <p>Gain the knowledge and insight to make better decisions about your health</p>
      </div>
      <IntegrationContentContainer>
        <Slider {...settings}>
          <div className="carousel-item">
            <img src="/public/images/WellnessPage/footer_2.png"/>
            <div>
              <p>Info that may surprise you and the ability to show what you know </p>
            </div>
          </div>
          <div className="carousel-item">
            <img src="/public/images/WellnessPage/footer_3a.png"/>
            <p>Tutorials and Exercises led by Fitness leaders at home or in the office </p>
          </div>
          <div className="carousel-item">
            <img src="/public/images/WellnessPage/footer_1.png"/>
            <p>Motivational messages to get you moving and inspire change  </p>
          </div>
        </Slider>
      </IntegrationContentContainer>
    </MiddleFooterSection>
  };
  footerSection = (history) => (
    <FooterSection>
      <div>
        <p>Are you ready to create a culture of wellbeing?</p>
        <Button onClick={()=> history.push('/contact')} display={'block'}>Get Started</Button>
      </div>
    </FooterSection>
  );
  render() {
    const {history} = this.props;
    return(
      <div>
        {this.TopPanelSection(history)}
        {this.MiddlePanelSection()}
        {this.MiddlePanelSectionSecond()}
        {this.middleFooterSection()}
        {this.footerSection(history)}
      </div>
    );
  }
}
WellnessPage.propTypes = {
  history: PropTypes.object,
};
export default WellnessPage;
import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const TopPanel = styled.div`
  float: left;
  width: 100%;
  background-image: linear-gradient(180deg, #E8FFD5 0%, #FFFFFF 100%);
  padding: 100px 90px 50px 98px;
  > div {
    float: left;
    width: 53%;
    display: flex;
    padding: 33px;
    //align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    //padding-right: 50px;
    padding-bottom: 15px;
     > p {
        font-family: ${BoldFont};
        font-size: 16px;
        line-height: 22px;
        color: #88C554;
        letter-spacing: 2px;
        font-weight: 800;
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 37px;
        margin: 0;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 57px;
        font-weight: 800;
        line-height: 57px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    } 
  }
  > div:last-child{
    width: 47%;
    > picture {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    img {
        width: 90%;
        margin: 0 auto;
    }
  }
  @media (max-width: 1100px) {
    padding: 50px 6vw;
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
     > h1 {
        font-size: 36px;
        margin: 0;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 57px;
        font-weight: 800;
        line-height: 57px;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        > picture {
          justify-content: center;
        }
        img {
            width: 80%;
        }
    }
  }
  @media (max-width: 500px) {
    padding: 50px 0vw;
      > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
    > h1 {
        font-size: 25px;
        margin: 0;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 57px;
        font-weight: 600;
        line-height: 57px;
        word-break: break-word;
        width: 100%;
        padding-left: 0px;
    }
    > p {
        font-family: ${BoldFont};
        font-size: 12px;
        line-height: 12px;
        color: #88C554;
        font-weight: 800;
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 0px;
     } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        margin: 0 auto;
        padding-right: 49px;
        img {
            width: 100%;
        }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;
const Button = styled.button`
  width: ${(props) => props.tryFree ? '200px' : '236px'};
  background-color: ${(props) => props.tryFree ? '#fff' : '#1573B9' };
  color: ${(props) => props.tryFree ? '#1573B9' : '#fff' };
  display: ${({display}) => display}; 
  padding: ${(props) => props.paddingVertical ? `${props.paddingVertical}` : '14px 10px'};
  border-radius: 40px;
  border: none;
  font-size: 16px;
  font-family: ${BoldFont};
  font-weight: 700;
  margin-left: 19px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
  
  &:hover, &:focus, &:active {
    outline: none;
  }
  
  @media (max-width: 1000px) {
   margin-top: ${(props) => props.tryFree ? props.marginTop : '35px'};
  }
`;
const TopPanelContent = styled.div`
  >p{
    font-family: ${MediumFont};
    font-size: 20px;
    line-height: 28px;
    color: #404555;
    margin:13px 0 15px 0;
    padding-left: 19px;
  }
    @media (max-width: 500px) {
    > p {
        font-family: ${MediumFont};
        font-size: 15px;
        line-height: 26px;
        color: #404555;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 25px;
        padding-left: 0px;
     } 
  }
`;

const MiddlePanel = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 90px 50px 98px;
  > div {
    float: left;
    width: 50%;
    flex-direction: column;
    
    img {
        width: 200px;
        float: right;
    }
      
  }
  > div:last-child{
    width: 50%;
    padding: 30px 70px 0 60px;
    float: left;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    }

  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 20px;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        padding: 0 62px;
        align-items: center;
    }
  }
  
@media (max-width: 500px) {
    padding: 15px 5px;
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
       img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        } 
    }
    > div:last-child{
        width: 100%;
        align-items: center;
        padding: 11px;
        margin: 0 auto;
        display: flex;
        > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding: 0; 
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;
const MiddlePanelSecond = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 100px 90px 147px 98px;
  > div {
    float: left;
    width: 50%;
    padding: 30px 23px 0 107px;
    flex-direction: column;
    > p {
        font-family: ${RegularFont};
        font-size: 20px;
        line-height: 30px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
        padding-left: 19px;
     }
    > h1 {
        font-size: 28px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
        padding-left: 19px;
    } 
  }
  > div:last-child{
    width: 50%;
    float: left;
    img {
        width: 200px;
        float: left;
    }
    
  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (max-width: 500px) {
    padding: 15px 5px;
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
        padding: 11px;
            > p {
        font-family: ${RegularFont};
        font-size: 15px;
        line-height: 15px;
        color: #404555;
        font-weight: normal;
        margin-top: 10px;
     }
    > h1 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #404555;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 250;
        word-break: break-word;
        width: 100%;
        padding: 0px;
    } 
    }
    > div:last-child{
        width: 100%;
        margin-bottom: 0px;
        margin-top: 30px;
        align-items: center;
        img {
           width: 200px;
           display: flex;
           margin: 0 auto;
           float: unset;
        }
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const MiddleFooterSection = styled.div`
  float: left;
  width: 100%;
  display: block;
  align-items: center;
  padding: 41px 0 50px 0;
  background-color: #0C202F;
   > div {
    float: left;
    width: 100%;
    padding: 30px 72px;
    flex-direction: column;
    > p {
        font-size: 34px;
        margin: 0 auto;
        text-align: center
        color: #fff;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 44px;
        font-weight: 400;
        word-break: break-word;
        width: 60%;
        display: block;
    } 
    
  }
  @media (max-width: 1300px) {
    padding: 50px 0;
    flex-direction: column;
    > div {
        width: 100%;
        padding: 0 62px;
        margin-bottom: 20px;
        > p {
        font-size: 25px;
        margin: 0 auto;
        color: #fff;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 80%;
        margin: 43px auto;
      } 
    }
  }
  @media (max-width: 500px) {
    padding: 15px 5px;
    text-align: center;
     > div {
        width: 100%;
        margin-bottom: 20px;
        padding: 11px;
      > p {
        font-size: 20px;
        margin: 0 auto;
        color: #fff;
        font-family: ${BoldFont};
        letter-spacing: 0;
        line-height: 33px;
        font-weight: 500;
        word-break: break-word;
        width: 100%;
    } 
    }
  }
`;
const FooterSection = styled.div`
  background-image: linear-gradient(180deg, #003148 0%, #006681 100%);
  padding: 50px 90px 50px 98px;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    padding: 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  p {
    font-size: 35px;
    line-height: 40px;
    color: #fff;
    font-family: ${RegularFont}
    font-weight: 400;
    text-align: center;
    margin: 20px 0px;
  }
  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
  }
  @media (max-width: 500px) {
   padding: 30px 10px;
   text-align: center;
   > div{
      padding: 10px 0px;
    p {
      font-size: 23px;
    }
   }
  }
`;
const IntegrationContentContainer = styled.div`
    position: relative;
    float: left;
    width: 100%
    display: block;
    align-items: center;
    background-color: transparent;
    min-height: 400px;
    padding: 41px 0 0px 0;
   > div{
    width: 100%;
    float: left;
    img {
        width: 90%;
        margin: 0 auto;
    }
     p{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: ${MediumFont};
    margin-top: 150px;
    padding: 0 103px;
    opacity: 0.5;
    }
     h4 {
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    font-family: ${BoldFont};
    opacity: 0.5;
    }
    
  }

   
  .slick-slide.carousel-item {
    opacity: 0.5;
    &.slick-active {
      opacity: 1;
     h4{
          opacity: 1;
     }
     p{
      opacity: 1;
     }
      &.slick-center {
        opacity: 0.5;
         h4{
          opacity: 1;
        }
      }
    }
  }
  .custom-dots.slick-dots  {
    padding: 0;
    list-style-type: none;
    margin: 0;
    bottom: 120px;
    margin-left: -33px;
    li {
      width: 168px;
      margin: 0;
      button {
        width: 130px;
        height: 5px;
        background-color: #2f8ddf;
        opacity: 0.5;
        padding: 0;
        h4{
          opacity: 0.5;
         }
        &:before {
          content: "";
        }
      }
      &.slick-active {
        button {
          opacity: 1;
        }
        h4{
          opacity: 1;
        }
      }
    }
   @media (max-width: 1000px) and (min-width: 701px){
    padding: 51px;
    bottom: 40px;
     li{
        width: 165px;
        button{
          width: 133px;
        }
      }
    }
   @media (max-width: 700px) and (min-width: 501px){
    padding: 51px;
    bottom: 100px;
    margin-left: -17px;
      li{
         width: 138px;
        button{
          width: 98px;
        }
      }
    }
    @media (max-width: 500px){
    padding: 51px;
    bottom: 51px;
    }
  }
    @media (max-width: 1000px) and (min-width: 701px){
    div {
    width: 100%;
    float: left;
    img {
        width: 90%;
        height: unset;
        margin: 0 auto;
    }
     p{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: ${MediumFont};
    margin-top: 164px;
    padding: 0 40px;
    opacity: 0.5;
    }
     h4 {
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    font-family: ${BoldFont};
    opacity: 0.5;
    }
  }
  }
   @media (max-width: 700px) and (min-width: 501px){
    div {
    width: 100%;
    float: left;
    img {
        width: 90%;
        height: unset;
        margin: 0 auto;
    }
     p{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-family: ${MediumFont};
    margin-top: 250px;
    padding: 0 40px;
    opacity: 0.5;
    }
     h4 {
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    font-family: ${BoldFont};
    opacity: 0.5;
    }
  }
  }
   @media (max-width: 500px){
    > div{
    width: 100%;
    float: left;
    img {
        width: 90%;
        height: unset;
        margin: 0 auto;
    }
    p{
    color: #fff;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    font-weight: 200;
    font-family: ${MediumFont};
    margin-top: 223px;
    padding: 0 13px;
    opacity: 0.5;
    }
    h4 {
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-weight: 400;
    font-family: ${BoldFont};
    opacity: 0.5;
    }
  }
  }
`;


export{TopPanel,Button,TopPanelContent,MiddlePanel,MiddlePanelSecond,MiddleFooterSection,FooterSection, IntegrationContentContainer};
